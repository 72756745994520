import { useEffect, useState } from "react";


//import from prime react
import InputCalendar from "../InputCalendar/InputCalendar";

//utils
import fechaStringAndHour from "../../Utils/fechaStringAndHour";
import stringToFechaAndHour from "../../Utils/stringToFechaAndHour";


//Dropdown options
const EstatusOptions = [
  { key: "Prospecto", name: "Prospecto" },
  { key: "Primera Cita", name: "Primera Cita" },
  { key: "Segunda Cita", name: "Segunda Cita" },
  { key: "Solicitud", name: "Solicitud" },
  { key: "Pagado", name: "Pagado" },
]

const EdadClienteOptions = [
  { key: 0, name: "Menor de 25" },
  { key: 1, name: "25 a 30" },
  { key: 2, name: "30 a 40" },
  { key: 3, name: "40 a 50" },
  { key: 4, name: "50 a 60" },
  { key: 5, name: "Mayor de 60" },
];
const GeneroClienteOptions = [
  { key: 1, name: "Masculino" },
  { key: 2, name: "Femenino" },
];
const ConHijosOptions = [
  { key: 1, name: "Si" },
  { key: 2, name: "No" },
  { key: 3, name: "No se" },
];

const ProductOptions = [
  { key: 1, name: "Privilegio" }, // VIDA
  { key: 2, name: "Platino" }, // VIDA
  { key: 3, name: "Dotal" }, // VIDA
  { key: 4, name: "Trasciende" }, // VIDA
  { key: 5, name: "Vision Plus" }, // VIDA
  { key: 6, name: "Profesional" }, // VIDA
  { key: 7, name: "Elige" }, // VIDA
  { key: 8, name: "Consolida PPR" }, // VIDA
  { key: 9, name: "Consolida Total" }, // VIDA
  { key: 10, name: "Proyecta" }, // VIDA
  { key: 11, name: "Proyecta Afectó" }, // VIDA
  { key: 12, name: "Capitaliza" },
  { key: 13, name: "Auto" },
  { key: 14, name: "GMM" },
  { key: 15, name: "Hogar Versátil" },
  { key: 16, name: "Mascota" },
];
const FocusOptions = [
  { key: 0, name: "Vida" },
  { key: 1, name: "GMM" },
  { key: 2, name: "Auto" },
  { key: 3, name: "Hogar Versatil" },
  { key: 4, name: "Mascota" },
  { key: 5, name: "Presentación" },
];

const PerfilClienteOptions = [
  { key: 1, name: "Asalariado" },
  { key: 2, name: "Empresario" },
  { key: 3, name: "Tiene negocio" },
  { key: 4, name: "Retirado" },
  { key: 5, name: "Otros" },
];
const SectorClienteOptions = [
  { key: 1, name: "Financiero" },
  { key: 2, name: "Automotriz" },
  { key: 3, name: "Alimentos" },
  { key: 4, name: "Farmaceutica" },
  { key: 5, name: "Farmaceutica" },
  { key: 6, name: "P. Independiente" },
  { key: 7, name: "Otros" },
];




export default function ({ row, index, handleChangeRow }) {
  const [initRender, setInitRender] = useState(1)

  const [editingPresupuesto, setEditingPresupuesto] = useState(false);
  const [presupuestoValue, setPresupuestoValue] = useState(row.presupuesto);

  const [editingComentarios, setEditingComentarios] = useState(false);
  const [comentariosValue, setComentariosValue] = useState(row.comentarios);


  //campos editables
  //ESTATUS
  const [estatusDelRegistro, setEstatusDelRegistro] = useState(EstatusOptions.find(option => option.name === row.estatus)?.name || "")

  //FECHA PRIMERA CITA
  const [editingPrimeraFecha, setEditingPrimeraFecha] = useState(false);
  const [PrimeraFechaValue, setPrimeraFechaValue] = useState(row.fecha_primera_cita ? stringToFechaAndHour(row.fecha_primera_cita) : '');

  //LUGAR
  const [editingLugar, setEditingLugar] = useState(false);
  const [LugarValue, setLugarValue] = useState(row.lugar);

  //NOMBRE CLIENTE
  const [editingNombreCliente, setEditingNombreCliente] = useState(false);
  const [NombreClienteValue, setNombreClienteValue] = useState(row.nombre_cliente);

  //TELEFONO
  const [editingTelefono, setEditingTelefono] = useState(false);
  const [TelefonoValue, setTelefonoValue] = useState(row.telefono_cliente);

  //EDAD CLIENTE
  const [EdadCliente, setEdadCliente] = useState(EdadClienteOptions.find(option => option.name === row.edad_cliente)?.name || "")

  //GENERO
  const [GeneroCliente, setGeneroCliente] = useState(GeneroClienteOptions.find(option => option.name === row.genero_cliente)?.name || "")

  //CON HIJOS
  const [ConHijos, setConHijos] = useState(ConHijosOptions.find(option => option.name === row.con_hijos)?.name || "")

  //PRODUCTO
  const [producto, setProducto] = useState(ProductOptions.find(option => option.name === row.producto)?.name || "")

  //ENFOQUE
  const [enfoque, setEnfoque] = useState(FocusOptions.find(option => option.name === row.enfoque)?.name || "")

  //FECHA SEGUNDA CITA
  const [editingSegundaFecha, setEditingSegundaFecha] = useState(false);
  const [SegundaFechaValue, setSegundaFechaValue] = useState(row.fecha_segunda_cita ? stringToFechaAndHour(row.fecha_segunda_cita) : "");

  //PERFIL CLIENTE
  const [PerfilCliente, setPerfilCliente] = useState(PerfilClienteOptions.find(option => option.name === row.perfil_cliente)?.name || "")

  //SECTOR CLIENTE
  const [SectorCliente, setSectorCliente] = useState(SectorClienteOptions.find(option => option.name === row.sector_cliente)?.name || "")


  //PRESUPUESTO


  //FUNCIONES PARA EDITAR CAMPOS
  //
  //FECHA PRIMERA CITA
  const handlePrimeraFechaChange = (e) => {
    setPrimeraFechaValue(e.value)
  };
  const handleSegundaFechaChange = (e) => {
    setSegundaFechaValue(e.value)
  };

  //LUGAR
  const handleLugarChange = (e) => {
    setLugarValue(e.target.value);
  };
  const handleLugarBlur = () => {
    setEditingLugar(false);
    handleChangeRow(row.uuid, 'lugar', LugarValue);
  };

  //NOMBRE CLIENTE
  const handleNombreClienteChange = (e) => {
    setNombreClienteValue(e.target.value);
  };
  const handleNombreClienteBlur = () => {
    setEditingNombreCliente(false);
    handleChangeRow(row.uuid, 'nombre_cliente', NombreClienteValue);
  };

  //TELEFONO
  const handleTelefonoChange = (e) => {
    setTelefonoValue((v) => e.target.validity.valid ? e.target.value : v);
  };
  const handleTelefonoBlur = () => {
    setEditingTelefono(false);
    handleChangeRow(row.uuid, 'telefono_cliente', TelefonoValue);
  };




  const handleComentariosChange = (e) => {
    setComentariosValue(e.target.value);
  };

  const handleComentariosBlur = () => {
    setEditingComentarios(false);
    handleChangeRow(row.uuid, 'comentarios', comentariosValue);
  };


  const handlePresupuestoChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.]/g, "");
    setPresupuestoValue(rawValue);
  };

  const handlePresupuestoBlur = () => {
    setEditingPresupuesto(false);
    handleChangeRow(row.uuid, 'presupuesto', presupuestoValue);
  };




  const handlePrimeraFechaOnBlur = (e) => {
    handleChangeRow(row.uuid, 'fecha_primera_cita', fechaStringAndHour(PrimeraFechaValue), setEditingPrimeraFecha);
  };
  const handleSegundaFechaOnBlur = (e) => {
    handleChangeRow(row.uuid, 'fecha_segunda_cita', fechaStringAndHour(SegundaFechaValue), setEditingSegundaFecha);
  };

  const handleChangeSelect = (e, state) => {

    let value = e.target.value;


    if (state == 'estatus') {
      setEstatusDelRegistro(value)

    }
    else if (state == 'edad_cliente') {
      setEdadCliente(value)

    }
    else if (state == 'genero_cliente') {
      setGeneroCliente(value)

    }
    else if (state == 'con_hijos') {
      setConHijos(value)

    }
    else if (state == 'producto') {
      setProducto(value)

    }
    else if (state == 'enfoque') {
      setEnfoque(value)

    }
    else if (state == 'perfil_cliente') {
      setPerfilCliente(value)

    }
    else if (state == 'sector_cliente') {
      setSectorCliente(value)

    }

    handleChangeRow(row.uuid, state, value)

  }







  //FUNCIONES DE FORMATEO
  const formatCurrency = (value) => {
    if (!value) return ''; // Si el valor es nulo o indefinido, devuelve una cadena vacía
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(Number(value));
  };

  function stringToFecha(fechaString) {
    try {
      if (fechaString) {
        const [dia, mes, año] = fechaString.split('/').map(part => parseInt(part, 10));
        return new Date(año, mes - 1, dia);
      }
    } catch {
      return ''
    }
  }









  useEffect(() => {
    if (initRender == 2) {
      // handleChangeRow(row, estatusDelRegistro)
    } else {
      setInitRender(2)
    }
  }, [])



  return (
    <tr>

      {/* ESTATUS */}
      <td>
        <select className="estatusSelect" value={estatusDelRegistro} onChange={e => handleChangeSelect(e, 'estatus')}>
          {EstatusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      {/* FECHA PRIMER CITA */}
      <td onClick={() => setEditingPrimeraFecha(true)}>
        {
          editingPrimeraFecha ?
            <InputCalendar
              value={PrimeraFechaValue}
              onChange={handlePrimeraFechaChange}
              onBlur={handlePrimeraFechaOnBlur}
            />

            :
            PrimeraFechaValue ? fechaStringAndHour(PrimeraFechaValue) : 'Sin datos'
        }
      </td>



      {/* LUGAR */}
      <td onClick={() => setEditingLugar(true)}>
        {editingLugar ? (
          <input
            type="text"
            value={LugarValue}
            onChange={handleLugarChange}
            onBlur={handleLugarBlur}
            autoFocus
          />
        ) : (
          LugarValue ? LugarValue : 'Sin datos'
        )}
      </td>



      {/* NOMBRE */}
      <td onClick={() => setEditingNombreCliente(true)}>
        {editingNombreCliente ? (
          <input
            type="text"
            value={NombreClienteValue}
            onChange={handleNombreClienteChange}
            onBlur={handleNombreClienteBlur}
            autoFocus
          />
        ) : (
          NombreClienteValue ? NombreClienteValue : 'Sin datos'
        )}
      </td>

      {/* TELEFONO */}
      <td onClick={() => setEditingTelefono(true)}>
        {editingTelefono ? (
          <input
            type="number"
            pattern="[+]?[0-9]*"
            value={TelefonoValue}
            onChange={handleTelefonoChange}
            onBlur={handleTelefonoBlur}
            autoFocus
          />
        ) : (
          TelefonoValue ? TelefonoValue : 'Sin datos'
        )}
      </td>

      {/* EDAD */}
      <td>
        <select value={EdadCliente} onChange={e => handleChangeSelect(e, 'edad_cliente')}>
          {EdadClienteOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      {/* GENERO */}
      <td>
        <select value={GeneroCliente} onChange={e => handleChangeSelect(e, 'genero_cliente')}>
          {GeneroClienteOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      {/* CON HIJOS */}
      <td>
        <select value={ConHijos} onChange={e => handleChangeSelect(e, 'con_hijos')}>
          {ConHijosOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>

      {/* PRODUCTOS */}
      <td>
        <select value={producto} onChange={e => handleChangeSelect(e, 'producto')}>
          {ProductOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>





      {/* FECHA_SEGUNDA_CITA */}
      <td onClick={() => setEditingSegundaFecha(true)}>
        {
          editingSegundaFecha ?
            <InputCalendar
              value={SegundaFechaValue}
              onChange={handleSegundaFechaChange}
              onBlur={handleSegundaFechaOnBlur}
            />

            :
            SegundaFechaValue ? fechaStringAndHour(SegundaFechaValue) : 'Sin datos'
        }
      </td>

      {/* ENFOQUE */}
      <td>
        <select value={enfoque} onChange={e => handleChangeSelect(e, 'enfoque')}>
          <option value="">Sin Datos</option>
          {FocusOptions.map(option => (
            <option key={option.key} value={option.name}>{option.name}</option>
          ))}
        </select>
      </td>


      {/* PERFIL CLIENTE */}
      <td>
        {
          <select
            id={"PerfilCliente" + row.index}
            autoFocus
            value={PerfilCliente}
            onChange={e => handleChangeSelect(e, 'perfil_cliente')}
          >
            <option value="">Sin Datos</option>
            {
              PerfilClienteOptions.map(option =>
                <option
                  key={option.key}
                  value={option.name}
                >
                  {option.name}
                </option>
              )
            }
          </select>
        }
      </td>


      {/* SECTOR CLIENTE */}
      <td>
        {
          <select
            id={"SectorCliente" + row.index}
            autoFocus
            value={SectorCliente}
            onChange={e => handleChangeSelect(e, 'sector_cliente')}
          >
            <option value="">Sin Datos</option>
            {
              SectorClienteOptions.map(option =>
                <option
                  key={option.key}
                  value={option.name}
                >
                  {option.name}
                </option>
              )
            }
          </select>
        }
      </td>

      {/* PRESUPUESTO */}
      <td onClick={() => setEditingPresupuesto(true)}>
        {editingPresupuesto ? (
          <input
            type="text"
            value={presupuestoValue}
            onChange={handlePresupuestoChange}
            onBlur={handlePresupuestoBlur}
            autoFocus
          />
        ) : (
          presupuestoValue ? formatCurrency(presupuestoValue) : 'Sin datos'
        )}
      </td>

      {/* COMENTARIOS */}
      <td onClick={() => setEditingComentarios(true)}>
        {editingComentarios ? (
          <textarea
            type="text"
            rows={4}
            cols={100}
            value={comentariosValue}
            onChange={handleComentariosChange}
            onBlur={handleComentariosBlur}
            autoFocus
            style={{ resize: "none" }}
          />
        ) : (
          comentariosValue ? comentariosValue : 'Sin comentarios'
        )}
      </td>

    </tr>
  )
}