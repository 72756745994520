export default function stringToFechaAndHour(fechaHoraString) {
    // Separar la fecha de la hora
    const [fecha, hora] = fechaHoraString.split('-');

    // Separar día, mes y año
    const [dia, mes, año] = fecha.split('/').map(Number);

    // Separar hora y minutos
    let [horaMinutos, ampm] = hora.split(/(AM|PM)/i);
    let [horas, minutos] = horaMinutos.split(':').map(Number);

    // Ajustar la hora según AM o PM
    if (ampm.toUpperCase() === 'PM' && horas < 12) {
        horas += 12;
    } else if (ampm.toUpperCase() === 'AM' && horas === 12) {
        horas = 0;
    }

    // Crear el objeto Date
    const fechaObj = new Date(año, mes - 1, dia, horas, minutos);

    return fechaObj;
}